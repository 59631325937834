body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Vertical Scrollbar */
::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

/* Horizontal Scrollbar */
::-webkit-scrollbar:horizontal {
  height: 6px;
  background-color: #f5f5f5;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
  border-radius: 6px;
  background-color: #f5f5f5;
}

/* Scrollbar Thumb */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
  background-color: rgba(0, 0, 0, 0.12);
}
